import { GlobalSpinnerService } from './global-spinner.service';
import { CookiesService } from './cookies.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginService } from '../../modules/login/services/login.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/modules/workspace/component-pieces/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public companyName: string = '';
  public userName: string = '';
  public userId: string = '';
  public userrole: string = '';

  public logoutCount: number = 0;

  public unsubscriber$ = new Subject();
  public cancelAllRequests$ = new Subject<void>();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private cookiesService: CookiesService,
    private globalSpinnerService: GlobalSpinnerService,
    private userService: UserService
  ) {
    this.getUserDetailsFromSessionOrCookie();
  }

  public getUserDetailsFromSessionOrCookie(): void {
    this.companyName = this.cookiesService.getCookieByName("companyName");
    this.userrole = this.cookiesService.getCookieByName("userRole");
    this.userName = this.cookiesService.getCookieByName("userName");
    this.userId = this.cookiesService.getCookieByName("userId");
  }

  public onErrorNavigation(errorCode: any) {
    switch (errorCode) {
      case 401:
        this.logout();
        break;

      default:
        break;
    }
  }

  public logout(): void {

    this.cancelAllRequests$.next();

    const data = {
      username: this.userName,
      company_name: this.companyName
    };

    this.loginService.logout(data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (result) => {
          if (this.cookiesService.checkCookie('accessToken')) {
            this.cookiesService.deleteCookie('accessToken');
          }

          if (this.cookiesService.checkCookie('refreshToken')) {
            this.cookiesService.deleteCookie('refreshToken');
          }

          if (this.cookiesService.checkCookie('selectedDocType')) {
            this.cookiesService.deleteCookie('selectedDocType');
          }

          this.userService.documentType$.next({
            text: 'Invoices',
            param: 'Invoices',
            labelKey: 'invoices'
          });

          this.cookiesService.deleteAllCookies();

          this.logoutCount++;
          // this.notificationService.showError("You Session has expired! Please Login Again!", "Session Error",);
          this.globalSpinnerService.isGlobalSpinner$.next(false);
          this.router.navigateByUrl("/error-401");

        },
        error: (error) => {
          this.notificationService.showError("Something Went Wrong!!!", error);
        },
        complete: () => { }
      })
  }



}
