import { CompaniesDetails } from "./company_specific_settings";


const baseUrls = {
  url_01: `/klearstack`,
  url_02: `/recon_service`,
  url_03: `/subscription`,
  url_04: `/pdf_splitter`,
};




export const environment: any = {
  production: true,

  companySpecificSettings: CompaniesDetails.GuideStarAI,

  AuthenticationAPIs: {
    login: `${baseUrls.url_01}/login`,
    logout: `${baseUrls.url_01}/logout`,
    getAccessToken: `${baseUrls.url_01}/get_access_token`,
    getAccessTokenfromRefreshToken: `${baseUrls.url_01}/getaccesstokenfromrefreshtoken`,
    getAccessTokenfromUserId: `${baseUrls.url_01}/getaccesstokenfromuserid`,
  },

  FreeTrialUserAPIs: {
    isValideBussinessEmail: `${baseUrls.url_01}/validatebusinessemail`,
    registerFreeTrialUser: `${baseUrls.url_01}/gettrialuserinfo`
  },

  BusinessEmailValidAPIs: {
    isValideBussinessEmail: `${baseUrls.url_01}/validatebusinessemail`,
  },

  SubscriptionUserAPIs: {
    registerForSubscription: `${baseUrls.url_03}/buy_subscription_new_company`,
    registerForCreditSubscription: `${baseUrls.url_03}/buy_subscription_new_company`
  },

  CreditUserDetailsAPIs: {
    creditUserDetails: `${baseUrls.url_03}/credit_details`
  },

  UserAPIs: {
    getUserDetails: `${baseUrls.url_01}/getuserdetails`,
    registerUserDetails: `${baseUrls.url_01}/register`,
    updateUserDetails: `${baseUrls.url_01}/updateuserdetails`,
    getUserSettings: `${baseUrls.url_01}/getmappingdata`,
    insertUserSettings: `${baseUrls.url_01}/updatemappingdata`,

    cancelSubscription: `${baseUrls.url_03}/cancel_subscription`,
    upgradeSubscription: `${baseUrls.url_03}/get_hosted_page_for_update_subscription`,
    renewSubscription: `${baseUrls.url_03}/buy_subscription_existing_company`,
    subscriptionForUserDetails: `${baseUrls.url_03}/subscriptions_for_customer_api`,
  },

  ImportAccountAPIs: {
    uploadSupplierAccountList: `${baseUrls.url_01}/importsupplieraccounts`,
    appendSupplierList: `${baseUrls.url_01}/appendexceldata`,
    getSupplierAccountList: `${baseUrls.url_01}/getsupplierlist`,
    getChartAccountList: `${baseUrls.url_01}/getaccountlist`,
    addSingleAccountInChartAccountList: `${baseUrls.url_01}/importsingleaccount`
  },

  DocumentsAPIs: {

    DocumentLists: {
      getAllDocumentsCounts: `${baseUrls.url_01}/getcount`,
      getAllDocumentsList: `${baseUrls.url_01}/getpageresult`,
      getErroredDocumentsList: `${baseUrls.url_01}/getsfdcerrorfilter`,
      getapproveDocumentsList: `${baseUrls.url_01}/getapprovedocument`,
      getUnapproveDocumentsList: `${baseUrls.url_01}/getapprovedocument`,
      getQuickBookDocumentsList: `${baseUrls.url_01}/getqbestatusdocuments`,
      getRuleErrorDocumentsList: `${baseUrls.url_01}/getruleerrordocuments`,
      getAutoRejectedDocumentsList: `${baseUrls.url_01}/getautorejectinvoices`,
      getUserDocumentsList: `${baseUrls.url_01}/getuserinvoices`,
      getRejectedDocumentsList: `${baseUrls.url_01}/getrejectdocument`,
      getObjectIdOfAllDocumentsList: `${baseUrls.url_01}/getobjectidsofdocuments`,

      getDocumentsByApplicationNumberFilter: `${baseUrls.url_01}/getapplicationfilter`,
      getDocumentsBySupplierNameFilter: `${baseUrls.url_01}/getfilterdocuments`,
      getDocumentsBymultipleParamsFilter: `${baseUrls.url_01}/getmultiresult`,

      getFilteredDocuments: `${baseUrls.url_01}/getfilterdocuments`,

      addStarredDocuments: `${baseUrls.url_01}/addstarredstatustodocuments`,
      getStarredDocuments: `${baseUrls.url_01}/getstarredstatustodocuments`,
    },

    Document: {
      deleteDocumentsByIdsList: `${baseUrls.url_01}/deleteresult`,
      approveDocumentsByIdsList: `${baseUrls.url_01}/updatereview`,
      getRuleApprovalByDocumentById: `${baseUrls.url_01}/get_rule_approval_by_document_id`,


      getDocumentById: `${baseUrls.url_01}/getdocument`,
      getBase64ImageByImagePath: `${baseUrls.url_01}/getimage`,
      deleteDocumentById: `${baseUrls.url_01}/deleteresult`,
      getOriginalDocument: `${baseUrls.url_01}/uploaded_file_download`,

      getTranslatedDocumentByLanguageAndId: `${baseUrls.url_01}/gettranslatedresult`,

      eligibleForRetraining: `${baseUrls.url_01}/eligible_for_retraining`,
      saveDocumentDetails: `${baseUrls.url_01}/saveresult`,
      updateDocumentDetails: `${baseUrls.url_01}/updateresult`,
      // updateDocumentDetails: `${baseUrls.url_01}/updateresult_test`,

      updateBulkDocumentDetails: `${baseUrls.url_01}/updatebulkdocuments`,

      exportXMLFileById: `${baseUrls.url_01}/download_xml`,
      exportTalltyXMLFileById: `${baseUrls.url_01}/get_download_tally_xml_records`,


      addNewFieldDocumentTypeWise: `${baseUrls.url_01}/add_new_field`,
      updateNewFieldDocumentTypeWise: `${baseUrls.url_01}/update_new_field`,
      getRuleStatusByDocumentId: `${baseUrls.url_01}/get_rule_status_by_document_id`,
      approveAllDocuments: `${baseUrls.url_01}/approvedallsearchrecords`,
      getFieldsKeyMapping: `${baseUrls.url_01}/get_key_mapping`,
    },

    Upload: {
      uploadDocumentAsBinaryFile: `${baseUrls.url_01}/invoiceprocessingui`,
      uploadDocumentsAsZipFile: `${baseUrls.url_01}/batchprocess`,
      retryUploadDocument: `${baseUrls.url_01}/retryinvoice`,
      getUploadInProgressDocuments: `${baseUrls.url_01}/getprogressdocuments`,
      getDocumentsUploadingStatus: `${baseUrls.url_01}/getstatusdocuments`,
      DeleteAllDocumentsUploadStatus: `${baseUrls.url_01}/deleteallstatusdocuments`,
      deleteDocumentUploadedStatusById: `${baseUrls.url_01}/deletestatusdocuments`,
      getAllDocumentsStatus: `${baseUrls.url_01}/getstatusalldocuments`,
      deleteAllDocumentsStatus: `${baseUrls.url_01}/deletestatusalldocuments`,
    },

    OCR: {
      getOCRText: `${baseUrls.url_01}/gettext`,
      getOCRTableColumnText: `${baseUrls.url_01}/getcolumn`,
      getOCRTableRowText: `${baseUrls.url_01}/getrow`,
      getOCRTableText: `${baseUrls.url_01}/gettable`
    },

  },

  SplitPdfAPIs: {
    splitPdfFile: `${baseUrls.url_04}/batch_upload`
  },

  InsightAPIs: {
    getUserInsights: `${baseUrls.url_01}/dailytotalforanalytics`,
    getApprovedVsUploadedDocumentCount: `${baseUrls.url_01}/analytics_get_uploaded_approved_count`,
    getApprovedAgainstCumulativeUploadedDailyCount: `${baseUrls.url_01}/analytics_getapprovedagainstcumulativeuploadeddailycount`,
    getTotalAmountUploadedDailyCount: `${baseUrls.url_01}/analytics_gettotalamountuploadeddailycount`,
    getAccuracyVsDate: `${baseUrls.url_01}/analytics_getaccuracyvsdate`,
    getTopTenSuppliersVsDate: `${baseUrls.url_01}/analytics_gettoptensuppliersvsdate`,
    getMonthlyPaymentDuePerSupplier: `${baseUrls.url_01}/analytics_getmonthlypaymentduepersupplier`,
    getMonthlyPaymentOverDuePerSupplier: `${baseUrls.url_01}/analytics_getmonthlypaymentoverduepersupplier`,
    getDailyDocumentCountAndPageCount: `${baseUrls.url_01}/analytics_dailyDocumentCountPageCount`,
    getDailyDocumentCountAndAmountDueInDayRange: `${baseUrls.url_01}/analytics_dailyDocumentCountandAmountDueinDayRange`,
    getTopTenSuppliersByAmountiInDayRange: `${baseUrls.url_01}/analytics_toptensuppliersbyamountindayrange`,
  },

  RuleEngineAPIs: {
    addRule: `${baseUrls.url_01}/insertruleset`,
    getRules: `${baseUrls.url_01}/getruleset`,
    updateRule: `${baseUrls.url_01}/updateruleset`,
    deleteRule: `${baseUrls.url_01}/deleteruleset`,
    copyRule: `${baseUrls.url_01}/copyruleset`,
    gerRuleById: `${baseUrls.url_01}/getrule`,
    executeReconciliationRuleById: `${baseUrls.url_02}/executereconciliationrulebyid`,
    viewReconciliationResultById: `${baseUrls.url_02}/viewreconciliationresultbyid`,
    downloadReconciliationReport: `${baseUrls.url_02}/recon_result_report`,
    getReconciliationRules: `${baseUrls.url_02}/view_recon_summary`,
    viewReconciliationReportSummaryById: `${baseUrls.url_02}/view_recon_result`,
    approveReconciliationDocumentsById: `${baseUrls.url_02}/approve_recon_documents`,
    getExecutionIdsOfReconciliationRule: `${baseUrls.url_02}/get_execution_ids_of_recon_rule`,
    getReconciliationResultFromExecutionId: `${baseUrls.url_02}/get_recon_result_from_execution_id`,
    getLineItemsForKnockOffAndExactMatchReconciliation: `${baseUrls.url_02}/get_line_items_for_knock_off`,

  },

  AuditReportAPIs: {
    getConsolidatedReconciliationResult: `${baseUrls.url_02}/get_recon_result_from_collection`,
    getAllRuleNameListFromTargetDataSource: `${baseUrls.url_02}/get_recon_rule_filtered_target_datasource`,
    getConsolidatedReconUserPreferences: `${baseUrls.url_02}/fetch_consolidated_recon_user_preferences`,
    editConsolidatedReconUserPreferences: `${baseUrls.url_02}/insert_consolidated_recon_user_preferences`,
    getSourceAuditReport: `${baseUrls.url_02}/fetch_source_audit_report`,
    getReconciliationResultById: `${baseUrls.url_02}/get_recon_rule_result_by_Objectid`,
    getAuditReportData: `${baseUrls.url_02}/get_audit_report_data`,
    getAuditReportCounts: `${baseUrls.url_02}/get_audit_report_counts`,
    auditApproveWithMultipleDocumentAndRule: `${baseUrls.url_02}/audit_approve_multirule_multidoc`,
    downloadAuditAReport: `${baseUrls.url_02}/download_audit_report`,
    getReconciliationRuleNames: `${baseUrls.url_02}/get_rules_for_collection`,
    instantReconciliation: `${baseUrls.url_02}/test_recon_run`,
    sendAuditReportOverMail: `${baseUrls.url_02}/send_exception_report_per_file`,
    removeReconApproval: `${baseUrls.url_02}/remove_recon_approval_multirule_multidoc`,
    instantReconciliationWithMultiDocument: `${baseUrls.url_02}/multidocinstantreconrun`,
  },

  DataSourceAPIs: {
    insertDataSource: `${baseUrls.url_01}/insertdatasource`,
    getDataSources: `${baseUrls.url_01}/getdatasource`,
    getDataSourceById: `${baseUrls.url_01}/getdatasourcebyid`,
    getDataSourceByName: `${baseUrls.url_01}/getdatasourcebyname`,
    deleteDataSource: `${baseUrls.url_01}/deletedatasource`,
    updateDataSource: `${baseUrls.url_01}/updatedatasource`,
    appendDataSource: `${baseUrls.url_01}/appenddatasource`,
    filterDataSource: `${baseUrls.url_01}/filterdatasource`,
    appendDataSourceWithSingleRecord: `${baseUrls.url_01}/appendsinglerecorddatasource`,
    editAndDeleteSelectedRecordInDataSource: `${baseUrls.url_01}/edit_value_and_delete_records_in_datasource`,
  },

  PaymentAPIs: {
    paymentScheduler: `${baseUrls.url_01}/payment_scheduler`,
  },

  PretrainedDocumentModel: {
    addCustomField: `${baseUrls.url_01}/addcustomfieldmappingdata`,
    deleteCustomField: `${baseUrls.url_01}/deletecustomfieldmappingdata`,
    editCustomField: `${baseUrls.url_01}/editcustomfieldmappingdata`,
    getTrainingAndTestingDocuments: `${baseUrls.url_01}/gettraintestdata`,
    completeTrainingDocuments: `${baseUrls.url_01}/completetrainingdata`,
    getDocumentProgressStatus: `${baseUrls.url_01}/getdocumentstatestatus`,
    getDocumentProgressCount: `${baseUrls.url_01}/getdocumentstagecount`,
    deleteDocumentUploadStatus: `${baseUrls.url_01}/deletestatus`,
    evaluateDocumentTraining: `${baseUrls.url_01}/evaluatetrainingresults`,
    getDocumentModelAccuracyState: `${baseUrls.url_01}/getmodelstats`,
    getfilteredDocumentFields: `${baseUrls.url_01}/getfilterfieldmappingdict`,
    moveDocumentFromTestingToTrainingPhase: `${baseUrls.url_01}/movetestingdatatotraining`,
    deployDocumentModel: `${baseUrls.url_01}/deploymodel`,
  },

  CustomDocumentModel: {

  },

  ApprovalWorkflowAPIs: {
    createOrUpdateAppprovalWorkflow: `${baseUrls.url_01}/create_update_workflow_for_collection`,
    getLisOfAppprovalWorkflow: `${baseUrls.url_01}/get_workflows_for_company`,
    getAppprovalWorkflowByCollectionName: `${baseUrls.url_01}/get_workflow_for_collection`,


  }



};
