import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {


  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {

  }



  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const isLoggedIn$ = this.authenticationService.isLoggedIn$();


    return forkJoin([isLoggedIn$])
      .pipe(
        switchMap(([isLoggedIn$]) => {
          if (isLoggedIn$) {
            return of(true);
          } else {
            this.router.navigate(['/login']);
            this.notificationService.showInfo("", "You Are Unauthorized. Please Login!");
            return of(false);
          }
        }
        )
      )




  }



}
