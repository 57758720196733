import { CookiesService } from 'src/app/shared/services/cookies.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiUrl } from 'src/app/shared/base_api/base-api-urls';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public notificationMessageDetails$ = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private cookiesService: CookiesService
  ) { }


  public login(data): Observable<any> {
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('username', data.username);
    fd.append('password', data.password);
    fd.append('company_name', data.company_name);
    return this.http.post(BaseApiUrl.LoginURL, fd, {
      responseType: 'text'
    });
  }

  public logout(data): Observable<any> {
    console.log("logout testing");

    if (this.cookiesService.checkCookie('accessToken')) {
      this.cookiesService.deleteCookie('accessToken');
    }

    if (this.cookiesService.checkCookie('refreshToken')) {
      this.cookiesService.deleteCookie('refreshToken');
    }

    this.cookiesService.deleteAllCookies();
    localStorage.clear();
    sessionStorage.clear();

    const fd = new FormData();
    fd.append('username', data.username);
    fd.append('company_name', data.company_name);
    return this.http.post(BaseApiUrl.LogOutURL, fd)
  }


  public getAccessToken(data): Observable<any> {
    const fd = new FormData();
    fd.append('username', data.username);
    fd.append('password', data.password);
    fd.append('company_name', data.company_name);
    return this.http.post(BaseApiUrl.LoginGetAccessToken, fd);
  }

  public getAccessTokenFromRefreshToken(refreshToken: string, companyName: string): Observable<any> {
    const fd = new FormData();
    fd.append('refresh_token', refreshToken);
    fd.append('company_name', companyName);
    return this.http.post(BaseApiUrl.LoginGetAccessTokenfromRefreshToken, fd);
  }


  public getAccessTokenFromUserId(userId: string, companyName: string): Observable<any> {
    const fd = new FormData();
    fd.append('company_name', companyName);
    fd.append('user_id', userId);
    return this.http.post(BaseApiUrl.LoginGetAccessTokenfromUserId, fd);
  }







}
